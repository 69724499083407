export const SquareGroup = ["warning", "primary", "warning1", "danger", "light", "warning", "success", "success"];

export const CourseBoxData = [
  {
    title: "Completed Courses",
    course: "100+",
    icon: "course-1",
    link: "View course",
  },
  {
    title: "In Progress Courses",
    course: "50+",
    icon: "course-2",
    link: "View course",
    color: "warning",
  },
];

export const LightCardBoxData = [
  {
    title: "Time Spent",
    total: 30,
    badge: 140,
    color: "success",
  },
  {
    title: "Lessons taken",
    total: 45,
    badge: 86,
    color: "success",
  },
  {
    title: "Exams passed",
    total: 12,
    badge: 120,
    color: "success",
  },
];

export const MyCourseData = [
  {
    title: "Management",
    arrowImage: "1",
    courseImage: "1",
  },
  {
    title: "Web Development",
    arrowImage: "2",
    courseImage: "2",
  },
  {
    title: "Business Analytics",
    arrowImage: "1",
    courseImage: "3",
  },
  {
    title: "Management",
    arrowImage: "3",
    courseImage: "4",
  },
];

export const ScheduleListData = [
  {
    title: "Web Design",
    image: "4.jpg",
    icon: "bag",
    icon2: "clock",
    date: "January 3, 2022",
    date2: "09.00 - 12.00 AM",
    color: "primary",
  },
  {
    title: "UI/UX Design",
    image: "2.jpg",
    icon: "bag",
    icon2: "clock",
    date: "Febuary 10, 2022",
    date2: "11.00 - 1.00 PM",
    color: "warning",
  },
];

export const ActiveLessonsList = [
  {
    image: "1.png",
    title: "UI/UX",
    subTitle: "Web design",
    chart: { color: ["var(--theme-default)", "var(--chart-progress-light)", "var(--chart-progress-light)", "var(--chart-progress-light)", "var(--chart-progress-light)", "var(--chart-progress-light)", "var(--chart-progress-light)"], series: [20, 5, 5, 5, 5, 5, 5] },
  },
  {
    image: "2.png",
    title: "Vue 3",
    subTitle: "JS Framework",
    chart: { color: ["var(--theme-default)", "var(--chart-progress-light)", "var(--chart-progress-light)", "var(--chart-progress-light)"], series: [50, 10, 10, 10] },
  },
  {
    image: "3.png",
    title: "Bootstrap 5",
    subTitle: "Framework",
    chart: { color: ["var(--theme-default)", "var(--chart-progress-light)", "var(--chart-progress-light)", "var(--chart-progress-light)", "var(--chart-progress-light)", "var(--chart-progress-light)", "var(--chart-progress-light)", "var(--chart-progress-light)", "var(--chart-progress-light)", "var(--chart-progress-light)"], series: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1] },
  },
];
